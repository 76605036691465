import React from 'react';
import { Link } from 'gatsby'
// import { graphql, StaticQuery } from "gatsby"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
// import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import HomeIcon from '@material-ui/icons/Home';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InfoIcon from '@material-ui/icons/Info';
import ProgressIcon  from '@material-ui/icons/ShowChartRounded';
import ExerciseIcon  from '@material-ui/icons/FitnessCenter';
import HealingIcon from '@material-ui/icons/Healing';
// import DevicesIcon from '@material-ui/icons/Devices';

import pumpSmartIcon from '../images/pumpsmart-icon.png';
import {
  Typography,
  CardMedia,
} from '@material-ui/core';



const styles = theme => ({
  drawerHeading: {
    textAlign: 'center',
    padding: theme.spacing.unit * 2,
    // paddingTop: theme.spacing.unit * 4,
    // paddingBottom: theme.spacing.unit * 4,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

class AppLayoutDrawer extends React.Component {
  

    constructor(props) {
        super(props);
        // console.log('AppLayoutDrawer.constructor()')
        // console.log('props', props)

        this.state = {
            open: props.open,
        };

        // console.log('this.state.open', this.state.open)
    }

    componentWillReceiveProps(props) {
        this.setState({
            open: props.open,
        });
    }
  

//   toggleDrawer = (side, open) => () => {
//     this.setState({
//       [side]: open,
//     });
//   };

  closeDrawer = () => {
      // console.log('closeDrawer')
    this.setState({
        open: false,
    }, () => {
        if (this.props.onClosed) {
            // console.log('onClosed()');
            this.props.onClosed();
        }
    });
  };

  openDrawer = () => {
    // console.log('openDrawer')
    this.setState({
        open: true,
    }, () => {
        if (this.props.onOpened) {
            // console.log('onOpened()');
            this.props.onOpened();
        }
    });
  };

  render() {
      // console.log('render');
      // console.log('this.state.open', this.state.open)
    const {
      classes,
      theme,
    } = this.props;

    const sideList = (
      <div className={classes.list}>
        <div className={classes.drawerHeading}>
          {/* <div style={{padding: theme.spacing.unit}}><img src={pumpSmartIcon} width="48" /></div> */}
          <CardMedia
            className={classes.media}
            style={{width: 48, height: 48, marginLeft: 'auto', marginRight: 'auto', marginBottom: theme.spacing.unit}}
            image={pumpSmartIcon}
            title=""
          />
          <Typography variant="h1" style={{fontSize: '1.3rem'}}>PumpSmart™</Typography>
        </div>
        
        <Divider />
        <List>
          {[
              {
                label: 'Home',
                icon: <HomeIcon />,
                uri: '/home/',
              },
              {
                label: 'My Progress',
                icon: <ProgressIcon />,
                uri: '/progress/',
              },
              {
                label: 'Exercise',
                icon: <ExerciseIcon />,
                uri: '/exercise/',
              },
            ].map((item) => {
              return (
                <ListItem key={item.label} component={Link} to={item.uri} button>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              );
            })}
        </List>
        <Divider />
        <List>
          {[
              {
                label: 'Procedures',
                icon: <HealingIcon />,
                uri: '/procedures/',
              },
              // {
              //   label: 'Devices',
              //   icon: <DevicesIcon />,
              //   uri: '/devices/',
              // },
            ].map((item) => {
              return (
                <ListItem key={item.label} component={Link} to={item.uri} button>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              );
            })}
        </List>
        <Divider />
        <List>
          {[
              {
                label: 'About PumpSmart',
                icon: <InfoIcon />,
                uri: '/about/',
              },
              {
                label: 'Contact Us',
                icon: <MailIcon />,
                uri: '/contact/',
              },
          ].map((item) => (
            <ListItem key={item.label} component={Link} to={item.uri} button>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
              {
                label: 'My Account',
                icon: <AccountBoxIcon />,
                uri: '/account/',
              },
              {
                label: 'Log Out',
                icon: <LogoutIcon />,
                uri: '/logout/',
              },
          ].map((item) => (
            <ListItem key={item.label} component={Link} to={item.uri} button>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div>
        <SwipeableDrawer
          open={this.state.open}
          onClose={this.closeDrawer}
          onOpen={this.openDrawer}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.closeDrawer}
            onKeyDown={this.closeDrawer}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

AppLayoutDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AppLayoutDrawer);

// export const query = graphql`
//     query {
//       fileName: file(relativePath: { eq: "images/pumpsmart-icon.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 48, maxHeight: 48) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `