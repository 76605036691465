// const config = require('gatsby-plugin-config').default;

module.exports.getBaseUrl = () => {
    // console.log('apiServiceUrl', apiServiceUrl);
    // return 'http://localhost:3336/pumpsmart/api/v1';
    // return 'https://drjoelkaplan.com/pumpsmart/api/v1';
    // return config.API_URL;
    const apiBaseUrl = process.env.GATSBY_API_URL;
    // console.log('apiBaseUrl', apiBaseUrl);
    return apiBaseUrl;
};