import decode from 'jwt-decode';
import { getBaseUrl } from './ApiBaseUrlService';

const loginUrl = getBaseUrl() + '/auth/local';

export default class AuthService {
    // Initializing important variables
    constructor() {
        // this.domain = domain || 'http://localhost:8080' // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(email, password) {
        // Get a token from api server using the fetch api
        return this.fetch(loginUrl, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password
            })
        }).then(res => {
            this.setToken(res.token) // Setting the token in localStorage
            return Promise.resolve(res);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        // console.log('getProfile', this.getProfile());
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        if (typeof window !== `undefined`) {
            localStorage.setItem('id_token', idToken)
        }
        this.setInvited((idToken.invited === true) ? true : false);
    }

    getToken() {
        // Retrieves the user token from localStorage
        if (typeof window !== `undefined`) {
            return localStorage.getItem('id_token')
        }
    }

    logout() {
        // Clear user token and profile data from localStorage
        if (typeof window !== `undefined`) {
            localStorage.removeItem('id_token');
        }
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    isInvited() {
        var result = false;

        if (typeof window !== `undefined`) {
            if (localStorage.getItem('ps_invited') === '1') {
                result = true;
            }
        }

        return result;
    }

    setInvited(isInvited) {
        if (typeof window !== `undefined`) {
            localStorage.setItem('ps_invited', (isInvited === true) ? '1' : '0');
        }
    }

    getMe() {
        var isInvited = this.isInvited();
        return this.fetch(getBaseUrl() + '/me', {
            method: 'GET'
        }).then((me) => {
            if (!isInvited && me.invited === true) {
                this.setInvited(true);
            }

            return me;
        });
    }

    async resetPassword(email) {
        var req = {
            email: email,
        };

        const res = await this.fetch(getBaseUrl() + '/reset_password', {
            method: 'POST',
            body: JSON.stringify(req),
        });

        console.log('res', res);

        return res;
    }

    async updatePassword(email, code, password) {
        var req = {
            email: email,
            code: code,
            password: password,
        };

        const res = await this.fetch(getBaseUrl() + '/update_password', {
            method: 'POST',
            body: JSON.stringify(req),
        });

        console.log('res', res);

        return res;
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}
