import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AuthService from '../services/AuthService';

import AppLayoutDrawer from '../components/AppLayoutDrawer';
// import ErrorBoundary from '../components/ErrorBoundary';
import {
  // Grid,
  Typography,
  // TextField,
  AppBar,
  Toolbar,
  IconButton,
  // MenuIcon,
} from '@material-ui/core'

// import {
//   AccountCircle, ArrowBackIos,
//   // Add,
//   // MenuOutlined,
// } from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu';


// import AddIcon from '@material-ui/icons/Add'

// import Layout from '../components/layout'
// import Image from '../components/image'
// import SEO from '../components/seo'


const styles = theme => ({
  root: {
    flexGrow: 1,
    // display: 'block',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  // fab: {
  //   position: 'absolute',
  //   bottom: theme.spacing.unit * 2,
  //   right: theme.spacing.unit * 3,
  // },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});


class AppLayout extends React.Component {
  static propTypes = {
      classes: PropTypes.object.isRequired,
      theme: PropTypes.object.isRequired,
      children: PropTypes.object.isRequired,
  };

  static defaultProps = {
    withAppMenu: false,
    actionButtonsLeft: [],
    actionButtons: [],
  };

  constructor(props) {
      super(props);

      this.auth = new AuthService();
      this.state = {
        // userMenuOpen: false,
        drawerOpen: false,
        userTokenData: null,
        anchorEl: null,
      };
  }

  componentDidMount() {
    this.initProfile();
  }

  initProfile() {
    if (this.auth.loggedIn()) {
      this.setState({
        userTokenData: this.auth.getProfile(),
      });
    }
  }


  // handleChange = event => {
  //   this.setState({ auth: event.target.checked });
  // };

  // onUserMenuOpen = () => {
  //   this.setState({
  //     userMenuOpen: true,
  //   });
  // };

  // onUserMenuClose = () => {
  //   this.setState({
  //     userMenuOpen: false,
  //   });
  // };

  onDrawerOpen = () => {
    this.setState({
      drawerOpen: true,
    });
  };

  onDrawerClose = () => {
    this.setState({
      drawerOpen: false,
    });
  };

  toggleDrawer = () => {
    // console.log('toggleDrawer');
    this.setState((state) => {
      return {
        drawerOpen: !state.drawerOpen,
      };
    })
  };
 

  render() {
    const {
        classes,
        // theme,
        children,
        actionButtons,
        actionButtonsLeft,
    } = this.props;

    const {
      // auth,
      // userTokenData,
      drawerOpen,
      // userMenuOpen,
      // anchorEl,
    } = this.state;

    // const classes = {};
    // const open = Boolean(anchorEl);

    
    // let userMenu;
    // if (!userTokenData) {
    //   let LoginLink = props => <Link to="/login" {...props} />
    //   userMenu = (
    //     <div>
    //       <Button color="inherit" style={{fontWeight: 'bold'}} component={LoginLink}>Sign-In</Button>
    //     </div>
    //   );
    // }
    
    // } else {
    //   userMenu = (
    //     <div>
          
    //       <IconButton
    //         aria-owns={open ? 'menu-appbar' : undefined}
    //         aria-haspopup="true"
    //         onClick={this.onUserMenuOpen}
    //         color="inherit"
    //       >
    //         <AccountCircle />
    //       </IconButton>
    //       <Menu
    //         id="menu-appbar"
    //         anchorEl={anchorEl}
    //         anchorOrigin={{
    //           vertical: 'top',
    //           horizontal: 'right',
    //         }}
    //         transformOrigin={{
    //           vertical: 'top',
    //           horizontal: 'right',
    //         }}
    //         open={userMenuOpen}
    //         onClose={this.onUserMenuClose}
    //       >
    //         <MenuItem onClick={this.onUserMenuClose}>Profile</MenuItem>
    //         <MenuItem onClick={this.onUserMenuClose}>My account</MenuItem>
    //       </Menu>
    //     </div>
    //   );
    // }

    // let drawerOpenStr;
    // if (drawerOpen === true) {
    //   drawerOpenStr = 'yes';
    // } else if (drawerOpen === false) {
    //   drawerOpenStr = 'no';
    // } else {
    //   drawerOpenStr = 'wtf';
    // }



    // let actionButtonsEl = <span></span>;
    // if (actionButtons.length > 0) {
    //   actionButtonsEl = (
    //     <div>
    //       {actionButtons.map((el, i) => {
    //         return <div style={{display: 'inline-block'}} key={i}>{el}</div>
    //       })}
    //     </div>
    //   );
    // }

    let appMenu, appMenuButton;
    if (!this.props.withAppMenu) {
      appMenuButton = <div></div>;
      appMenu = <div></div>;
    } else {
      appMenuButton = (
        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer}>
          <MenuIcon />
        </IconButton>
      );
      
      appMenu = <AppLayoutDrawer open={drawerOpen} onOpened={this.onDrawerOpen} onClosed={this.onDrawerClose} />;
    }


    
    let actionButtonsLeftEl = <span></span>;
    if (actionButtonsLeft.length > 0) {
      actionButtonsLeftEl = (
        <div>
          {actionButtonsLeft.map((el, i) => {
            return <div key={`action_button_left_${i}`} style={{display: 'inline-block'}} key={i}>{el}</div>
          })}
        </div>
      );
    }
    
    


    return (
      // <ErrorBoundary>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {appMenuButton}
            {actionButtonsLeftEl}
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {/* My Progress */}
              {this.props.title || 'PumpSmart'}
            </Typography>
            <div>
              {/* {userMenu} */}
              {actionButtons}
              {/* <IconButton
                // aria-owns={open ? 'menu-appbar' : undefined}
                // aria-haspopup="true"
                // onClick={this.handleMenu}
                color="primary"
              >
                <AddIcon />
              </IconButton> */}
            </div>
          </Toolbar>
        </AppBar>

        {appMenu}

        {children}
      </div>
      // </ErrorBoundary>
    );

    // return (
    //   <div className={styles.root}>
    //     <SEO title="PumpSmart: Sexual Performance Tracker" keywords={[`PumpSmart`, `sex performance tracker`]} />
    //     {children}
    //   </div>
    // );
  }
}


// export default AppLayout;
// export default withStyles(styles, { withTheme: true })(AppLayout);
export default withStyles(styles, { withTheme: true })(AppLayout);